<script setup lang="ts">
import type { User } from "@/server/users";
import { ConfigProvider } from "@/components/ui/config-provider";
import ProfileDropdownMenu from "./ProfileDropdownMenu.vue";
import { buttonVariant } from "@/components/ui/button";
import MobileMenu from "./MobileMenu.vue";
import SearchDialog from "./SearchDialog.vue";

const props = defineProps<{
	islandId: number;
	user?: User | null;
}>();
</script>

<template>
	<ConfigProvider :island-id="props.islandId">
		<header
			class="pointer-events-auto sticky top-0 z-20 w-full border-b border-zinc-200 bg-white bg-opacity-90 px-5 py-3 backdrop-blur-[20px] backdrop-saturate-150 md:px-10"
		>
			<div class="container flex items-center justify-between gap-6">
				<div class="flex flex-1 items-center justify-start gap-5">
					<a
						href="/"
						class="flex items-center gap-1.5 text-lg font-bold tracking-tight text-zinc-900"
					>
						<div class="size-5 rounded-full bg-indigo-500"></div>
						SiteName
					</a>
					<div class="hidden md:flex">
						<a
							href="/conferences"
							class="flex h-9 items-center rounded-md px-3 text-sm font-semibold transition-colors ease-out hover:bg-zinc-400/15"
						>
							Conferences
						</a>
						<a
							href="/categories"
							class="flex h-9 items-center rounded-md px-3 text-sm font-semibold transition-colors ease-out hover:bg-zinc-400/15"
						>
							Categories
						</a>
					</div>
				</div>
				<SearchDialog class="flex-1" />
				<div class="flex flex-1 items-center justify-end gap-2">
					<MobileMenu />
					<ProfileDropdownMenu v-if="user" :user="user" />
					<div v-else class="hidden md:flex md:gap-1">
						<a
							:class="
								buttonVariant({
									variant: 'secondary',
									size: 'sm',
									class: 'shrink-0',
								})
							"
							href="/auth/login"
						>
							Log in
						</a>
						<a
							:class="
								buttonVariant({
									variant: 'primary',
									size: 'sm',
									class: 'shrink-0',
								})
							"
							href="/auth/register"
						>
							Register
						</a>
					</div>
				</div>
			</div>
		</header>
	</ConfigProvider>
</template>
