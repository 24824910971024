<script setup lang="ts">
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
	DropdownMenuLabel,
} from "@/components/ui/dropdown-menu";
import SettingsIcon from "@/icons/settings.svg?component";
import LogoutIcon from "@/icons/logout.svg?component";
import LoaderIcon from "@/icons/loader.svg?component";
import PeopleIcon from "@/icons/people.svg?component";
import BellIcon from "@/icons/bell.svg?component";
import CalendarCheckIcon from "@/icons/calendar-check.svg?component";
import CategoryIcon from "@/icons/categories.svg?component";
import CalendarEventIcon from "@/icons/calendar-event.svg?component";
import { computed } from "vue";
import { useLogoutMutation } from "@/services/auth.client";
import type { User } from "@/server/users";
import { useQueryClient } from "@tanstack/vue-query";
import { currentUserQuery } from "@/services/users.client";
import { getCDNImageURL } from "@/utils/images";

const props = defineProps<{ user: User }>();

const queryClient = useQueryClient();
queryClient.setQueryData(currentUserQuery.queryKey, props.user);

const { mutate: logout, isPending } = useLogoutMutation();

const avatar = computed(() => {
	return props.user.avatarKey
		? getCDNImageURL(props.user.avatarKey)
		: "/images/avatar.webp";
});
</script>

<template>
	<DropdownMenu :modal="false">
		<DropdownMenuTrigger class="flex items-center gap-2.5">
			<span class="hidden text-sm font-semibold md:block">
				{{ user.name }}</span
			>
			<img
				alt="User avatar"
				:src="avatar"
				class="size-10 shrink-0 rounded-full border border-black/10 transition-colors group-hover:border-black/20"
			/>
		</DropdownMenuTrigger>
		<DropdownMenuContent align="end" class="min-w-48">
			<DropdownMenuLabel>
				<div class="flex flex-col">
					<span>{{ user.name }}</span>
					<span class="text-xs font-normal text-zinc-600">
						{{ user.email }}
					</span>
				</div>
			</DropdownMenuLabel>
			<DropdownMenuSeparator />
			<DropdownMenuGroup>
				<DropdownMenuItem as="a" href="/user/conferences">
					<CalendarEventIcon class="mr-2 size-4 stroke-[1.5] text-zinc-800" />
					<span>My conferences</span>
				</DropdownMenuItem>
				<DropdownMenuItem as="a" href="/user/conferences/subscribed">
					<BellIcon class="mr-2 size-4 stroke-[1.5] text-zinc-800" />
					<span>Subscriptions</span>
				</DropdownMenuItem>
				<DropdownMenuItem as="a" href="/user/conferences/booked">
					<CalendarCheckIcon class="mr-2 size-4 stroke-[1.5] text-zinc-800" />
					<span>Bookings</span>
				</DropdownMenuItem>
			</DropdownMenuGroup>
			<template v-if="user.isAdmin">
				<DropdownMenuSeparator />
				<DropdownMenuGroup>
					<DropdownMenuItem as="a" href="/admin/categories/">
						<CategoryIcon class="mr-2 size-4 stroke-[1.5] text-zinc-800" />
						<span>Categories</span>
					</DropdownMenuItem>
					<DropdownMenuItem as="a" href="/admin/conferences/">
						<CalendarEventIcon class="mr-2 size-4 stroke-[1.5] text-zinc-800" />
						<span>Conferences</span>
					</DropdownMenuItem>
					<DropdownMenuItem as="a" href="/admin/users/">
						<PeopleIcon class="mr-2 size-4 stroke-[1.5] text-zinc-800" />
						<span>Users</span>
					</DropdownMenuItem>
				</DropdownMenuGroup>
			</template>
			<DropdownMenuSeparator />
			<DropdownMenuGroup>
				<DropdownMenuItem as="a" href="/user/settings">
					<SettingsIcon class="mr-2 size-4 stroke-[1.5] text-zinc-800" />
					<span>Settings</span>
				</DropdownMenuItem>
				<DropdownMenuItem @select.prevent="logout()">
					<LoaderIcon
						class="mr-2 size-4 animate-spin stroke-[1.5]"
						v-if="isPending"
					/>
					<LogoutIcon v-else class="mr-2 size-4 stroke-[1.5] text-zinc-800" />
					<span>Logout</span>
				</DropdownMenuItem>
			</DropdownMenuGroup>
		</DropdownMenuContent>
	</DropdownMenu>
</template>
