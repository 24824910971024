<script setup lang="ts">
import SearchIcon from "@/icons/search.svg?component";
import { publicConferencesPageQuery } from "@/services/conferences.client";
import { queryOptions, useQuery } from "@tanstack/vue-query";
import {
	AvatarRoot,
	AvatarImage,
	AvatarFallback,
	ComboboxItem,
} from "radix-vue";
import {
	Combobox,
	ComboboxAnchor,
	ComboboxContent,
	ComboboxEmpty,
	ComboboxInput,
} from "@/components/ui/combobox";
import { computed, ref } from "vue";
import {
	CONFERENCE_PLATFORM_ICONS,
	CONFERENCE_PLATFORM_LABELS,
	detectConferencePlatform,
} from "@/services/platform";
import type { PublicConference } from "@/server/conferences/shared";
import MapPinIcon from "@/icons/map-pin.svg?component";
import { useThrottleFn } from "@vueuse/core";
import { AvatarGroup } from "@/components/ui/overflow-indicator";
import LoaderIcon from "@/icons/loader.svg?component";
import { formatDateToMonthDay } from "@/utils/dayjs";
import { getCDNImageURL } from "@/utils/images";

const isOpen = ref(false);
const search = ref("");

const setSearchWithThrottle = useThrottleFn(
	(newValue: string) => {
		search.value = newValue;
	},
	300,
	true,
);

const { data: conferences, isLoading } = useQuery(
	computed(() => {
		return queryOptions({
			...publicConferencesPageQuery({
				pageSize: 6,
				search: search.value,
				minEndsAt: new Date(),
			}),
			enabled: search.value !== "",
			placeholderData: (data, query) => {
				if (query?.isActive()) {
					return data;
				}
			},
		});
	}),
);

function getPlatformDetails(conference: PublicConference) {
	if (conference.type === "OFFLINE" || !conference.link) {
		return { label: undefined, icon: undefined };
	}

	const platform = detectConferencePlatform(conference.link);
	if (!platform) {
		return { label: "Unknown platform", icon: undefined };
	}

	return {
		label: CONFERENCE_PLATFORM_LABELS[platform],
		icon: CONFERENCE_PLATFORM_ICONS[platform],
	};
}
</script>

<template>
	<Combobox
		:filter-function="(data) => data"
		:search-term="search"
		@update:search-term="setSearchWithThrottle"
		v-model:open="isOpen"
	>
		<ComboboxAnchor class="border-transparent bg-zinc-700/5">
			<SearchIcon class="-ml-1 mr-0.5 size-5 stroke-[1.5] text-zinc-400" />
			<ComboboxInput
				placeholder="Search"
				@focus="() => (search ? (isOpen = true) : undefined)"
			/>
		</ComboboxAnchor>
		<ComboboxContent
			class="-left-1/2 -right-1/2 mx-auto w-[150%] px-1 py-0 shadow-md shadow-zinc-500/15"
		>
			<ComboboxEmpty>
				<template v-if="search === ''">
					Start typing to search for conferences
				</template>
				<div
					v-else-if="isLoading"
					class="flex w-full items-center justify-center"
				>
					<LoaderIcon
						class="mr-1.5 size-4 animate-spin stroke-[1.5] text-zinc-400"
					/>
					Loading...
				</div>
				<template v-else> No conferences found </template>
			</ComboboxEmpty>
			<template v-if="conferences?.items">
				<ComboboxItem
					v-for="conference of conferences.items"
					:value="conference"
					:key="conference.id"
					as="a"
					:href="`/conferences/${conference.id}`"
					class="group flex min-w-0 gap-3 rounded p-3 data-[highlighted]:bg-zinc-100"
				>
					<div class="flex min-w-0 flex-1 flex-col">
						<h3
							class="mb-1 line-clamp-2 text-sm font-bold underline-offset-2 group-hover:underline"
						>
							{{ conference.name }}
						</h3>
						<span class="text-sm font-medium text-gray-500">
							<template v-if="conference.type === 'OFFLINE'">
								<MapPinIcon
									class="mr-0.5 inline-block size-4 align-sub text-zinc-400"
								/>
								{{ conference.country }}, {{ conference.city }}
							</template>
							<template v-else>
								<component
									:is="getPlatformDetails(conference).icon"
									class="mx-1 inline-block size-4 align-sub"
								/>
								{{ getPlatformDetails(conference).label }}
							</template>

							&bull; {{ formatDateToMonthDay(conference.startsAt) }}
						</span>
					</div>
					<AvatarGroup
						v-if="conference.speakers.length > 0"
						class="flex"
						:items="conference.speakers"
						:max="3"
						v-slot="{ visibleItems, surplusCount }"
					>
						<AvatarRoot
							v-for="speaker of visibleItems"
							class="-mr-2 size-6 select-none overflow-hidden rounded-full border-2 border-white last:mr-0"
						>
							<AvatarImage
								class="size-full"
								height="24"
								width="24"
								:src="
									speaker.avatarKey
										? getCDNImageURL(speaker.avatarKey)
										: '/images/placeholder_image.webp'
								"
							/>
							<AvatarFallback
								class="mx-auto size-full text-center text-sm font-medium text-zinc-500"
							>
								{{ speaker.name[0] }}
							</AvatarFallback>
						</AvatarRoot>
						<div
							v-if="surplusCount > 0"
							class="flex size-7 items-center justify-center rounded-full border-2 border-white bg-indigo-100 text-center text-xs font-semibold leading-6 text-indigo-500"
						>
							+{{ surplusCount }}
						</div>
					</AvatarGroup>
				</ComboboxItem>
			</template>
		</ComboboxContent>
	</Combobox>
</template>
