<script setup lang="ts" generic="T">
import { Primitive, type PrimitiveProps } from "radix-vue";
import { computed } from "vue";

const props = defineProps<
	PrimitiveProps & {
		items: T[];
		max: number;
	}
>();

const visibleItems = computed(() => {
	if (props.items.length <= props.max) {
		return props.items;
	}
	return props.items.slice(0, props.max - 1);
});

const surplusCount = computed(() => {
	if (props.items.length <= props.max) {
		return 0;
	}
	return props.items.length - props.max + 1;
});
</script>

<template>
	<Primitive :as="props.as" :as-child="props.asChild">
		<slot
			:items="items"
			:visibleItems="visibleItems"
			:surplusCount="surplusCount"
		/>
	</Primitive>
</template>
